import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { ArticleCard } from "../components/Article/ArticleCard"
import { CurveCarveTop, CurveBulgeBottom } from "../components/Curves/Curve"
import { Newsletter } from "../components/Misc/Newsletter"
import { SideMenuNews } from "../components/Navigation/SidemenuNews"

const NewsSector = ({ data }) => {
  const { contentfulHubSector } = data

  return (
    <Layout>
      <SEO
        title={
          contentfulHubSector.subtitle &&
          `Latest news regarding ${contentfulHubSector.subtitle}`
        }
        description={
          contentfulHubSector.subtitle &&
          `Latest news regarding ${contentfulHubSector.subtitle}`
        }
      />
      <Container>
        <Row>
          <Col>
            <h1>
              {contentfulHubSector.subtitle &&
                `Latest news regarding ${contentfulHubSector.subtitle}`}
            </h1>
          </Col>
        </Row>
      </Container>
      <CurveCarveTop variant="light" />
      <div className="bg-light  py-5">
        <Container>
          <Row>
            <Col lg={8}>
              <Row>
                {contentfulHubSector.article ? (
                  contentfulHubSector.article.map(article => {
                    return (
                      <Col sm={6} key={article.id}>
                        <ArticleCard article={article} />
                      </Col>
                    )
                  })
                ) : (
                  <div>No Articles</div>
                )}
              </Row>
            </Col>
            <Col>
              <SideMenuNews />
            </Col>
          </Row>
        </Container>
      </div>
      <CurveBulgeBottom variant="light" />
      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query NewsSectorQuery($id: String) {
    contentfulHubSector(id: { eq: $id }) {
      subtitle
      article {
        ...ArticleListFragment
      }
    }
  }
`

export default NewsSector
